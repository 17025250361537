import axios from "@/config/httpConfig";

//生成二维码/条形码规则设置


//更新、增加二维码/条形码规则
export function upsertCodeRule(data) {
    return axios.post("/codeRule/upsertCodeRule", data);
}

// 判断是否可以勾选同时启用二维码或条形码
export function canChoose(data) {
    return axios.post("/codeRule/canChoose", data);
}

//获取对象字段列表
export function getFieldList(data) {
    return axios.post("/field/getFieldList", data);
}

// 获取规则列表
export function getCodeRuleList(data) {
    return axios.post("/codeRule/getCodeRuleList", data);
}

//获取规则详情
export function getCodeRule(data) {
    return axios.post("/codeRule/getCodeRule", data);
}

//删除二维码规则
export function delCodeRule(data) {
    return axios.post("/codeRule/delCodeRule", data);
}

// 更新启用/禁用状态
export function updateStatus(data) {
    return axios.post("/codeRule/updateStatus", data);
}