<!-- 开发人员: 彭雨萏 -->
<template>
  <div class="qrCodeSettingBox">
    <!-- 标题 -->
    <div class="qrCodeSetting_title">
      <!-- 生成条码/二维码规则 -->
      {{ $t("label.title.qrcodeOrbarcode") }}
    </div>
    <!-- 搜索+新增规则 -->
    <div class="qrCodeSetting_searchAndAdd">
      <!-- 搜索 -->
      <div class="qrCodeSetting_search">
        <i class="el-icon-search" @click="getNewRule()"></i>
        <!-- 输入规则名称 -->
        <el-input
          v-model="searchRule"
          :placeholder="$t('label.enter.rule.name')"
          @input="searchRuleFn"
          @keyup.enter.native="getNewRule()"
        ></el-input>
      </div>
      <!-- 新增 -->
      <div class="qrCodeSetting_add" @click="addRule('1')">
        {{ $t("label.add.new.rule") }}
        <!-- 新增生成规则 -->
      </div>
    </div>
    <!-- 规则表格 -->
    <div class="qrCodeSetting_content">
      <!-- 已启用规则 -->
      <div class="content_enabledRules">
        <div class="enabledRules_title">
          {{ $t("label.enable.rules.enableOnlyOneqrcodeOrbarcode") }}
          <!-- 已启用规则（每个对象仅支持启用一个二维码规则或条形码规则） -->
        </div>
        <el-table
          :data="enabledRulesData"
          style="width: 100%"
          height="calc(100% - 30px)"
          :empty-text="$t('label.noRules.have.been.enabled')"
        >
          <!-- 还没有已启用规则 -->
          <!-- :empty-text="$t('label.noRules.have.been.enabled')" -->
          <!-- 编号: label.quickbooks.viewlist.number -->
          <el-table-column
            prop="name"
            :label="$t('label.quickbooks.viewlist.number')"
            min-width="160"
          >
          </el-table-column>
          <!-- 规则名称: label.assignment.setup.list.rulename -->
          <el-table-column
            prop="ruleName"
            :label="$t('label.assignment.setup.list.rulename')"
            min-width="130"
          >
          </el-table-column>
          <!-- 关联对象:  label.associations -->
          <el-table-column
            prop="obj"
            :label="$t('label.associations')"
            min-width="150"
          >
          </el-table-column>
          <!-- 码类型:  label.codeType -->
          <el-table-column
            prop="codeType"
            :label="$t('label.codeType')"
            min-width="120"
          >
          </el-table-column>
          <!-- 码标准:  label.codeNorm -->
          <el-table-column
            prop="codeNorm"
            :label="$t('label.codeNorm')"
            min-width="140"
          >
          </el-table-column>
          <!-- 创建人:  label.createname -->
          <el-table-column
            prop="createname"
            :label="$t('label.createname')"
            min-width="130"
          >
          </el-table-column>
          <!-- 创建时间:  label.createdate -->
          <el-table-column :label="$t('label.createdate')" min-width="170">
            <template slot-scope="scope">{{
              scope.row.createdate | datetimeFormat(countryCode)
            }}</template>
          </el-table-column>
          <!-- 操作:  label.operate -->
          <el-table-column :label="$t('label.operate')" min-width="230">
            <template slot-scope="scope">
              <div class="operations">
                <div @click="stopCodeStatus(scope.row)">
                  <!-- 停用 -->
                  {{ $t("label.setting.disable") }}
                </div>
                <span>|</span>
                <div @click="addRule('2', scope.row)">
                  <!-- 编辑 -->
                  {{ $t("label.queue.edit") }}
                </div>
                <!-- <span>|</span>
                <div>复制</div> -->
                <span>|</span>
                <div @click="deleteRule(scope.row)">
                  <!-- 删除 -->
                  {{ $t("label.queue.delete") }}
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 未启用规则 -->
      <div class="content_ruleNotEnabled">
        <div class="ruleNotEnabled_title">
          <!-- 未启用规则 -->
          {{ $t("label.rule.not.enabled") }}
        </div>
        <el-table
          :data="ruleNotEnabledData"
          style="width: 100%"
          height="calc(100% - 30px)"
          :empty-text="$t('label.there.are.no.unenabled.rules')"
        >
          <!-- 还没有未启用规则 -->
          <!-- :empty-text="$t('label.there.are.no.unenabled.rules')" -->
          <!-- 编号: label.quickbooks.viewlist.number -->
          <el-table-column
            prop="name"
            :label="$t('label.quickbooks.viewlist.number')"
            min-width="160"
          >
          </el-table-column>
          <!-- 规则名称: label.assignment.setup.list.rulename -->
          <el-table-column
            prop="ruleName"
            :label="$t('label.assignment.setup.list.rulename')"
            min-width="130"
          >
          </el-table-column>
          <!-- 关联对象:  label.associations -->
          <el-table-column
            prop="obj"
            :label="$t('label.associations')"
            min-width="150"
          >
          </el-table-column>
          <!-- 码类型:  label.codeType -->
          <el-table-column
            prop="codeType"
            :label="$t('label.codeType')"
            min-width="120"
          >
          </el-table-column>
          <!-- 码标准:  label.codeNorm -->
          <el-table-column
            prop="codeNorm"
            :label="$t('label.codeNorm')"
            min-width="140"
          >
          </el-table-column>
          <!-- 创建人:  label.createname -->
          <el-table-column
            prop="createname"
            :label="$t('label.createname')"
            min-width="130"
          >
          </el-table-column>
          <!-- 创建时间:  label.createdate -->
          <el-table-column :label="$t('label.createdate')" min-width="170">
            <template slot-scope="scope">{{
              scope.row.createdate | datetimeFormat(countryCode)
            }}</template>
          </el-table-column>
          <!-- 操作:  label.operate -->
          <el-table-column :label="$t('label.operate')" min-width="230">
            <template slot-scope="scope">
              <div class="operations">
                <div @click="openCodeStatus(scope.row)">
                  <!-- 启用 -->
                  {{ $t("label.recordtype.enable") }}
                </div>
                <span>|</span>
                <div @click="addRule('2', scope.row)">
                  <!-- 编辑 -->
                  {{ $t("label.queue.edit") }}
                </div>
                <!-- <span>|</span>
                <div>复制</div> -->
                <span>|</span>
                <div @click="deleteRule(scope.row)">
                  <!-- 删除 -->
                  {{ $t("label.queue.delete") }}
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--
      新增生成规则弹框
      编辑生成规则弹框
    -->
    <newGenerationRule
      :newGenerationRuleTo="newGenerationRuleTo"
      :titleI="titleI"
      :addOrEditTitle="addOrEditTitle"
      :addNewCodeForm="addNewCodeForm"
      @newGenerationRuleCancel="newGenerationRuleCancel"
      @newGenerationRuleConfirm="newGenerationRuleConfirm"
      @nextStep="nextStep"
    />
    <!-- 删除规则弹框 -->
    <deleteRule
      :deleteRuleTo="deleteRuleTo"
      @deleteRuleCancel="deleteRuleCancel"
      @deleteRuleConfirm="deleteRuleConfirm"
    />
    <!-- 选择显示在二维码/条形码周围的字段 -->
    <nextStepCpn
      :nextStepCpnTo="nextStepCpnTo"
      @nextStepCpnCancel="nextStepCpnCancel"
      @nextStepCpnConfirm="nextStepCpnConfirm"
      :canChooseResult="canChooseResult"
      :viewUnselectedFieldList="viewUnselectedFieldList"
      :viewSelectedFieldLists="viewSelectedFieldList"
      :allowCheckNumber="allowCheckNumber"
    />
  </div>
</template>

<script>
import * as Time from "@/utils/time";
import deleteRule from "./components/deleteRule.vue";
import newGenerationRule from "./components/newGenerationRule.vue";
import nextStepCpn from "./components/nextStepCpn.vue";
import {
  getCodeRuleList, //获取规则列表
  upsertCodeRule, //更新、增加二维码/条形码规则
  canChoose, //判断是否可以勾选同时启用二维码或条形码
  getFieldList, //获取对象字段列表
  getCodeRule, //获取规则详情
  delCodeRule, //删除二维码规则
  updateStatus, //更新启用/禁用状态
} from "./api";
export default {
  components: {
    deleteRule, // 删除
    newGenerationRule, //新建
    nextStepCpn, // 下一步
  },
  data() {
    return {
      searchRule: "", //搜索规则
      newGenerationRuleTo: false, // 新增and编辑
      deleteRuleTo: false, //删除
      nextStepCpnTo: false, //选择显示在二维码/条形码周围的字段
      titleI: "", // 1为新增,2为编辑
      addOrEditTitle: "", // 新增或编辑标题
      addNewCodeForm: {}, //新增规则时的form表单数据
      enabledRulesData: [], // 已启用规则
      ruleNotEnabledData: [], // 未启用规则
      codeRuleId: "", //每条规则的ID
      canChooseResult: true, //判断是否可以勾选同时启用二维码或条形码
      viewUnselectedFieldList: [], //资产中的全部标准字段
      viewSelectedFieldList: [], //已选字段
      selectedWords: {}, //选择的字段，中间用，分割
      allowCheck: "0", //勾选同时启用二维码条形码（勾选传1不勾选0）
      allowCheckNumber: "",
      ruleId: "", //规则id,新建时为空
      datetimeFormat: Time.datetimeFormat(this.$cookies.get("countryCode")),
      countryCode: this.$cookies.get("countryCode"),
    };
  },
  mounted() {
    this.getCodeRuleList(); //获取规则列表
  },
  methods: {
    // 按钮搜索
    getNewRule() {
      this.getCodeRuleList();
    },
    // 清空
    searchRuleFn() {
      if (!this.searchRule) {
        this.getCodeRuleList();
        this.clearKeyWord();
      }
    },
    //清空关键字
    
    clearKeyWord() {
      this.searchRule = "";
    },
    //获取规则列表
    async getCodeRuleList() {
      let params = {
        keyWord: this.searchRule,
      };
      let codeRuleListRes = await getCodeRuleList(params);
      this.enabledRulesData = codeRuleListRes.data.enableList; //已启用规则
      this.ruleNotEnabledData = codeRuleListRes.data.disableList; //未启用规则
      codeRuleListRes.data.enableList.map((item) => {
        if (item.codeType == "qrcode" && item.codeNorm == "QRcodeStandard") {
          // item.codeType = "二维码";
          item.codeType = this.$i18n.t("label.qrcode");
          item.codeNorm = "QRcode";
        } else if (
          item.codeType == "barcode" &&
          item.codeNorm == "Code128Standard"
        ) {
          item.codeType = this.$i18n.t("label.barcode");
          // item.codeType = "条形码";
          item.codeNorm = "Code128";
        }
      });
      codeRuleListRes.data.disableList.map((item) => {
        if (item.codeType == "qrcode" && item.codeNorm == "QRcodeStandard") {
          // item.codeType = "二维码";
          item.codeType = this.$i18n.t("label.qrcode");
          item.codeNorm = "QRcode";
        } else if (
          item.codeType == "barcode" &&
          item.codeNorm == "Code128Standard"
        ) {
          item.codeType = this.$i18n.t("label.barcode");
          // item.codeType = "条形码";
          item.codeNorm = "Code128";
        }
      });
    },
    // 打开新增/编辑生成规则弹框
    async addRule(i, value) {
      this.titleI = i;
      this.newGenerationRuleTo = true;
      if (this.titleI == 1) {
        this.addOrEditTitle = this.$i18n.t("label.add.new.rule");
        // 新增生成规则  this.$i18n.t("label.add.new.rule")
        this.initializationData(); // 新建规则需初始化数据
      } else {
        // 编辑生成规则  this.$i18n.t("label.edit.new.rule")
        this.addOrEditTitle = this.$i18n.t("label.edit.new.rule");
        // 获取规则详情
        this.ruleId = value.id;
        let getCodeRuleRes = await getCodeRule({
          id: value.id,
        });
        this.addNewCodeForm = {
          ruleName: getCodeRuleRes.data.codeRule.ruleName, //规则名称
          associations: this.$i18n.t("label.asset"), //关联对象 资产:this.$i18n.t("label.asset")
          codeType: getCodeRuleRes.data.codeRule.codeType, //码类型
          codeStandards: getCodeRuleRes.data.codeRule.codeNorm, //码标准
          insert: getCodeRuleRes.data.codeRule.fieldorurl, //插入
        };
        if (getCodeRuleRes.data.codeRule.codeSet) {
          this.$set(
            this.addNewCodeForm,
            "codeSet",
            getCodeRuleRes.data.codeRule.codeSet
          ); //代码集
        }
        if (
          getCodeRuleRes.data.codeRule.fieldorurl ==
          this.$i18n.t("label.field.insert")
        ) {
          // 插入字段:this.$i18n.t("label.field.insert")
          this.$set(
            this.addNewCodeForm,
            "insertWord",
            getCodeRuleRes.data.codeRule.insertField
          ); //插入字段
        } else {
          this.$set(
            this.addNewCodeForm,
            "insertURL",
            getCodeRuleRes.data.codeRule.insertField
          ); //插入URL
        }
        this.selectedWords = getCodeRuleRes.data.codeRule.codeFields; // 已选择的字段
        this.allowCheckNumber = getCodeRuleRes.data.codeRule.allowCheck;
        this.viewSelectedFieldList = getCodeRuleRes.data.selectFieldList; //已选字段
        this.viewUnselectedFieldList = getCodeRuleRes.data.unSelectFieldList; //资产的全部字段
        this.$forceUpdate();
      }
    },
    // 对规则id、表单数据、已选择的字段数据做初始化
    
    initializationData() {
      this.ruleId = "";
      this.addNewCodeForm = {
        ruleName: "", //规则名称
        associations: this.$i18n.t("label.asset"), //关联对象
        codeType: "qrcode", // 码类型
        codeStandards: "QRcodeStandard", // 码标准
        codeSet: "", //代码集
        insert: this.$i18n.t("label.field.insert"), //插入  // this.$i18n.t("label.field.insert")
        insertWord: "serialnumber", //插入字段
        insertURL: "", //插入URL
      };
      this.viewSelectedFieldList = [
        // 资产名称  this.$i18n.t("label.ServiceCloud.zichanmingcheng")
        {
          id: "ffe20210127asset0034",
          label: this.$i18n.t("label.ServiceCloud.zichanmingcheng"),
          labelName: this.$i18n.t("label.ServiceCloud.zichanmingcheng"),
        },
        // 安装日期  this.$i18n.t("label.appexchange.installedpage.installdate")
        {
          id: "ffe20210127asset0019",
          label: this.$i18n.t("label.appexchange.installedpage.installdate"),
          labelName: this.$i18n.t(
            "label.appexchange.installedpage.installdate"
          ),
        },
        // 序列号  this.$i18n.t("label.list.serial.number")
        {
          id: "ffe20210127asset0021",
          label: this.$i18n.t("label.list.serial.number"),
          labelName: this.$i18n.t("label.list.serial.number"),
        },
      ];
    },
    // 取消新增/编辑
    newGenerationRuleCancel() {
      this.newGenerationRuleTo = false;
      this.clearAddForm();
    },
    // 下一步
    async nextStep(form) {
      this.addNewCodeForm = JSON.parse(JSON.stringify(form));
      this.newGenerationRuleTo = false;
      this.nextStepCpnTo = true;
      /* 新建的时候 就直接根据接口来判断
      // 编辑时先判断这个allowCheck 如果是1 说明她是勾选的状态 就可以编辑勾选或者不勾选，如果不是1 你就需求去请求接口看返回true 还是false 来判断能不能勾选 */
      // 点击新增时的下一步时，判断权限
      if (this.titleI == "1") {
        let canChooseRes = await canChoose({
          objId: "asset",
        });
        if (canChooseRes.data == true) {
          this.canChooseResult = true;
        } else {
          this.canChooseResult = false;
        }
      } else {
        /* this.titleI == "1" 此时为编辑
          this.allowCheckNumber == '1' 有权限 true (可勾选)
          this.allowCheckNumber == '0' 再进行判断权限
         */
        if (this.allowCheckNumber == "1") {
          this.canChooseResult = true;
        } else {
          let canChooseRes = await canChoose({
            objId: "asset",
          });
          if (canChooseRes.data == true) {
            this.canChooseResult = true;
          } else {
            this.canChooseResult = false;
          }
        }
      }
      let fieldListRes = await getFieldList({
        objId: "asset",
      });
      /* 特殊处理：
        使用公共组件穿梭框 搜索时为 label 所以要将labelName 赋值给 label
      */
      fieldListRes.data.map((item) => {
        return (item.label = item.labelName);
      });
      this.viewUnselectedFieldList = fieldListRes.data;
      this.$forceUpdate();
    },

    // "编辑规则"弹框的保存
    async newGenerationRuleConfirm(form) {
      this.addNewCodeForm = JSON.parse(JSON.stringify(form));
      this.newGenerationRuleTo = false;
      this.saveTheRules();
      this.clearAddForm();
      this.$forceUpdate();
    },
    //点击"下一步"后 -- 可选字段弹框的 "取消"
    nextStepCpnCancel() {
      this.nextStepCpnTo = false;
      this.clearAddForm();
    },
    // 点击"下一步"后 -- 可选字段弹框的 "保存"
    nextStepCpnConfirm(selectedWords, allowCheck) {
      this.selectedWords = selectedWords;
      this.allowCheck = allowCheck;
      if (this.selectedWords && this.selectedWords.split(",").length > 3) {
        this.$message({
          // 最多选择三个字段! this.$i18n.t("label.select.up.to.three.fields")
          message: this.$i18n.t("label.select.up.to.three.fields"),
          type: "error",
        });
      } else {
        this.nextStepCpnTo = false;
        this.saveTheRules();
        this.clearAddForm();
        this.$forceUpdate();
      }
    },
    // 保存或更新规则
    
    async saveTheRules() {
      let params = {
        id: this.ruleId, // 规则id,新建时为空
        ruleName: this.addNewCodeForm.ruleName, //规则名称
        objId: "asset", // 资产对象ID
        codeType: this.addNewCodeForm.codeType, // 码类型
        codeNorm: this.addNewCodeForm.codeStandards, //码标准
        codeSet: this.addNewCodeForm.codeSet, //代码集
        fieldorurl: this.addNewCodeForm.insert, //插入字段、插入url
        codeFields: this.selectedWords, //选择的字段，中间用，分割
        allowCheck: this.allowCheck, // 勾选同时启用二维码条形码（勾选传1不勾选0）
      };

      //插入字段或url的内容
      // this.$i18n.t("label.field.insert")
      if (this.addNewCodeForm.insert == this.$i18n.t("label.field.insert")) {
        params.insertField = this.addNewCodeForm.insertWord;
      } else {
        params.insertField = this.addNewCodeForm.insertURL;
      }
      await upsertCodeRule(params);
      this.getCodeRuleList();
      if (this.ruleId == "") {
        // 新增规则成功! this.$i18n.t("label.newRule.successfully.added")
        this.$message({
          message: this.$i18n.t("label.newRule.successfully.added"),
          type: "success",
        });
      } else {
        // 规则更新成功! this.$i18n.t("label.rule.updated.successfully")
        this.$message({
          message: this.$i18n.t("label.rule.updated.successfully"),
          type: "success",
        });
      }
      this.clearAddForm();
    },
    // 清空form表单数据
    clearAddForm() {
      this.addNewCodeForm.ruleName = "";
      this.addNewCodeForm.codeType = "qrCode";
      this.addNewCodeForm.codeStandards = "QRcodeStandard";
      this.addNewCodeForm.codeSet = "";
      this.addNewCodeForm.insert = this.$i18n.t("label.field.insert"); // 插入字段 this.$i18n.t("label.field.insert")
      this.addNewCodeForm.insertWord = "serialnumber";
      this.addNewCodeForm.insertURL = "";
    },
    // 打开删除规则弹框
    deleteRule(value) {
      this.deleteRuleTo = true;
      this.codeRuleId = value.id;
    },
    // 取消"删除规则"
    deleteRuleCancel() {
      this.deleteRuleTo = false;
    },
    // 确定"删除规则"
    async deleteRuleConfirm() {
      this.deleteRuleTo = false;
      let deleteRuleRes = await delCodeRule({
        id: this.codeRuleId,
      });
      if (deleteRuleRes.result == true) {
        // 删除规则成功! this.$i18n.t("label.delete.rule.successfully")
        this.$message({
          message: this.$i18n.t("label.delete.rule.successfully"),
          type: "success",
        });
      }
      this.getCodeRuleList();
    },
    //禁用规则
    async stopCodeStatus(value) {
      let stopCodeStatusRes = await updateStatus({
        id: value.id,
        isEnable: "0",
        codeType: value.code_type,
        objId: "asset",
      });
      if (stopCodeStatusRes.result == true) {
        // 规则禁用成功! this.$i18n.t("label.rule.disabled.successfully")
        this.$message({
          message: this.$i18n.t("label.rule.disabled.successfully"),
          type: "success",
        });
      }
      this.getCodeRuleList();
    },
    //启用规则
    async openCodeStatus(value) {
      let openCodeStatusRes = await updateStatus({
        id: value.id,
        isEnable: "1",
        codeType: value.code_type,
        objId: "asset",
      });
      if (openCodeStatusRes.result == true) {
        // 规则启用成功! this.$i18n.t("label.rule.enabled.successfully")
        this.$message({
          message: this.$i18n.t("label.rule.enabled.successfully"),
          type: "success",
        });
      }
      this.getCodeRuleList();
    },
  },
};
</script>
<style lang="scss" scoped>
.qrCodeSettingBox {
  width: 100%;
  height: 100%;
  padding: 20px 10px;
  // 标题
  .qrCodeSetting_title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    height: 26px;
    line-height: 26px;
  }
  // 搜索+新增规则
  .qrCodeSetting_searchAndAdd {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0 22px 0px;
    // 搜索框
    .qrCodeSetting_search {
      width: 383px;
      height: 30px;
      position: relative;
      // 搜索icon
      .el-icon-search {
        width: 14px;
        height: 14px;
        position: absolute;
        left: 10px;
        top: 8px;
        z-index: 999;
        cursor: pointer;
      }
      .el-input {
        height: 30px !important;
        line-height: 30px !important;
        ::v-deep .el-input__inner {
          height: 30px !important;
          line-height: 30px !important;
          padding-left: 28px;
          &::placeholder {
            font-size: 12px;
            color: #888888;
          }
        }
      }
    }
    // 新增生成规则
    .qrCodeSetting_add {
      min-width: 126px;
      height: 32px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #006dcc;
      border-radius: 3px;
      color: white;
      cursor: pointer;
      padding: 0 10px;
    }
  }

  // 规则表格
  .qrCodeSetting_content {
    width: 100%;
    height: calc(100% - 105px);
    /* 已启用规则/未启用规则 (通用样式) */
    .content_enabledRules {
      height: 50%;
    }
    .content_ruleNotEnabled {
      height: calc(50% - 24px);
    }
    .content_enabledRules,
    .content_ruleNotEnabled {
      width: 100%;
      .enabledRules_title,
      .ruleNotEnabled_title {
        width: 100%;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        background: #f5f5f5;
        border-radius: 2px;
        border: 1px solid #dedcda;
        color: #333333;
        padding-left: 10px;
      }
      // 表格样式

      ::v-deep .el-table {
        .el-table__header {
          // th > .cell {
          //   overflow: hidden;
          //   white-space: nowrap;
          //   text-overflow: ellipsis;
          // }
          .has-gutter {
            color: #333333;
            font-weight: bold;
          }
        }
        .el-table__body {
          td > .cell {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .el-table__row {
            color: #333333;
            .operations {
              display: flex;
              color: #006dcc;
              div {
                margin-right: 3px;
                cursor: pointer;
              }
              span {
                display: block;
                margin-right: 3px;
              }
            }
          }
        }
      }
      // 表格最下面的线
      ::v-deep .el-table::before {
        height: 0px;
      }
      // 暂无数据时的样式
      ::v-deep .el-table__empty-block {
        display: block;
        text-align: left;
        padding-left: 10px;
      }
    }
    // 已启用规则
    .content_enabledRules {
      .enabledRules_title {
      }
    }
    // 未启用规则
    .content_ruleNotEnabled {
      margin-top: 24px;
      .ruleNotEnabled_title {
      }
    }
  }
}
</style>
