<!--
  选择显示在二维码/条形码周围的字段
-->
<template>
  <!-- 选择显示在二维码/条形码周围的字段 label.select.the.fields.around.code -->
  <el-dialog
    :visible.sync="nextStepCpnToProp"
    :title="$t('label.select.the.fields.around.code')"
    :before-close="handleClose"
    :close-on-click-modal="false"
    @click.native.capture="hideBtn"
  >
    <div class="dialog-body">
      <oneShuttle
        ref="oneShuttle"
        :viewUnselectedFieldList="viewUnselectedFieldList"
        :viewSelectedFieldArr="viewSelectedFieldList"
        @selFun="getSelFun"
      />
      <div class="payAttention">
        {{ $t("label.select.up.three.fields") }}
        <!-- 注意：最多选择三个字段 -->
      </div>
      <el-checkbox v-model="checked" v-if="canChooseResult == true">
        {{ $t("label.use.rule.when.both") }}
        <!-- 同时启用条形码和二维码规则时，使用此规则 -->
      </el-checkbox>
      <el-checkbox v-else disabled v-model="checked1">
        {{ $t("label.use.rule.when.both") }}
        <!-- 同时启用条形码和二维码规则时，使用此规则 -->
      </el-checkbox>
      <div class="titleYa">
        <!-- 示例 -->
        {{ $t("label.example") }}
      </div>
      <!-- <svg class="icon qrCodeImg" aria-hidden="true" slot="reference">
        <use href="#icon-qrCode"></use>
      </svg> -->
      <img src="@/assets/qrCodeSetting/qrCode.svg" alt="" />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="nextStepCpnCancel">{{
        $t("button_partner_cancel")
      }}</el-button>
      <el-button type="primary" @click="nextStepCpnConfirm">{{
        $t("label.ems.confirm")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import oneShuttle from "@/components/ViewBox/oneShuttle.vue";
export default {
  components: {
    oneShuttle,
  },
  props: {
    nextStepCpnTo: {
      type: Boolean,
      default: false,
    },
    canChooseResult: {
      type: Boolean,
      default: true,
    },
    viewUnselectedFieldList: {
      type: Array,
      default: () => [],
    },
    // 回显已选择的字段数据
    viewSelectedFieldLists: {
      type: Array,
      default: () => [],
    },
    allowCheckNumber: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      nextStepCpnToProp: this.nextStepCpnTo,
      selectedWords: "", //已选字段
      allowCheck: "0", //勾选同时启用二维码条形码（勾选传1不勾选0）
      checked: false,
      checked1: false,
      viewSelectedFieldList: [
        // 资产名称  this.$i18n.t("label.ServiceCloud.zichanmingcheng")
        {
          id: "ffe20210127asset0034",
          label: this.$i18n.t("label.ServiceCloud.zichanmingcheng"),
          labelName: this.$i18n.t("label.ServiceCloud.zichanmingcheng"),
        },
        // 安装日期  this.$i18n.t("label.appexchange.installedpage.installdate")
        {
          id: "ffe20210127asset0019",
          label: this.$i18n.t("label.appexchange.installedpage.installdate"),
          labelName: this.$i18n.t(
            "label.appexchange.installedpage.installdate"
          ),
        },
        // 序列号  this.$i18n.t("label.list.serial.number")
        {
          id: "ffe20210127asset0021",
          label: this.$i18n.t("label.list.serial.number"),
          labelName: this.$i18n.t("label.list.serial.number"),
        },
      ], // 已选字段
    };
  },
  watch: {
    // 编辑时监听已选择的字段数据，做回显
    viewSelectedFieldLists: {
      handler() {
        this.viewSelectedFieldList = JSON.parse(
          JSON.stringify(this.viewSelectedFieldLists)
        );
      },
      deep: true,
      immediate: true,
    },
    allowCheckNumber: {
      handler() {
        if (this.allowCheckNumber == "1") {
          this.checked = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    //取消可选成员和可选字段组件的icon
    hideBtn() {
      if(this.$refs.oneShuttle !== undefined) {
        this.$refs.oneShuttle.hideBtn()
      }
    },
    handleClose() {
      this.$emit("nextStepCpnCancel");
    },
    nextStepCpnCancel() {
      this.$emit("nextStepCpnCancel");
    },
    // 保存
    nextStepCpnConfirm() {
      if (this.canChooseResult == false) {
        this.allowCheck = "0";
      } else {
        if (this.checked == true) {
          this.allowCheck = "1";
        } else {
          this.allowCheck = "0";
        }
      }
      this.$emit("nextStepCpnConfirm", this.selectedWords, this.allowCheck);
    },
    //获取可选成员
    getSelFun(n) {
      this.selectedWords = n;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 572px;
}
.dialog-body {
  color: #080707;
  ::v-deep .shu_left {
    height: 170px !important;
  }
  // 注意
  .payAttention {
    color: #888888;
  }
  ::v-deep .el-checkbox {
    padding: 10px 0;
    .el-checkbox__label {
      color: #888888;
    }
    // 被选中的样式
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #080707 !important;
    }
  }
  .titleYa {
    font-weight: bold;
  }
  img {
    display: inline-block;
    width: 495px;
    margin-top: 4px;
  }
}
</style>
