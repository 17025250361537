<!--
  新增生成规则弹框
  编辑生成规则弹框
-->
<template>
  <el-dialog
    :visible.sync="newGenerationRuleToProp"
    :title="addOrEditTitle"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- 规则名称 label.assignment.setup.list.rulename -->
        <el-form-item
          :label="$t('label.assignment.setup.list.rulename')"
          prop="ruleName"
        >
          <el-input v-model="ruleForm.ruleName"></el-input>
        </el-form-item>
        <!-- 关联对象 label.associations -->
        <el-form-item :label="$t('label.associations')" prop="associations">
          <el-input v-model="ruleForm.associations" disabled></el-input>
        </el-form-item>
        <!-- 码类型 label.codeType -->
        <el-form-item :label="$t('label.codeType')" prop="codeType">
          <el-select v-model="ruleForm.codeType" @change="changeCodeType">
            <el-option
              v-for="(item, index) in codeTypeArr"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 码标准 label.codeNorm -->
        <el-form-item :label="$t('label.codeNorm')" prop="codeStandards">
          <el-select v-model="ruleForm.codeStandards">
            <el-option
              v-for="(item, index) in codeStandardsArr"
              :key="index"
              :label="item.label"
              :value="item.value"
              disabled
            ></el-option>
          </el-select>
          <el-tooltip placement="top">
            <div
              slot="content"
              v-html="$t('label.qrcodeSetting.detailed.information')"
            >
              {{ $t("label.qrcodeSetting.detailed.information") }}
              <!-- QR码比较普通条码可以存储更多数据，也无需要像<br />普通条码般在扫描时需要直线对准扫描仪。因此其<br />应用范围已经扩展到包括产品跟踪，物品识别，文<br />档管理，营销等方面。 -->
            </div>
            <svg
              class="icon helpImg"
              aria-hidden="true"
              v-show="ruleForm.codeStandards == 'QRcodeStandard'"
            >
              <use href="#icon-help"></use>
            </svg>
          </el-tooltip>
        </el-form-item>
        <!-- 代码集 label.setting.codeSet -->
        <el-form-item
          :label="$t('label.setting.codeSet')"
          prop="codeSet"
          class="codeSetClass"
        >
          <!-- <el-select
            v-model="ruleForm.codeSet"
            :disabled="ruleForm.codeStandards == 'QRcodeStandard'"
            placeholder=""
          >
            <el-option
              v-for="(item, index) in codeSetArr"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select> -->
          <el-input v-model="ruleForm.codeSet" disabled></el-input>
        </el-form-item>
        <!-- 单选 -->
        <el-form-item label="" prop="insert" class="radioClass">
          <el-radio-group v-model="ruleForm.insert">
            <!-- 插入字段 label.field.insert -->
            <el-radio
              :label="$t('label.field.insert')"
              @change="changeRadio('1', 'ruleForm')"
            ></el-radio>
            <!-- 插入URL label.insertURL -->
            <el-radio
              :label="$t('label.insertURL')"
              @change="changeRadio('2')"
            ></el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 选"插入字段"时展示的select框 -->
        <el-form-item
          :label="$t('label.field.insert')"
          prop="insertWord"
          v-if="ruleForm.insert == $t('label.field.insert')"
        >
          <!-- 请选择插入字段 vue_label_email_insert_field -->
          <el-select
            v-model="ruleForm.insertWord"
            :placeholder="$t('vue_label_email_insert_field')"
          >
            <el-option
              v-for="(item, index) in insertWordArr"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 选"插入URL"时展示的input框 -->
        <el-form-item
          label="URL"
          prop="insertURL"
          v-if="ruleForm.insert == $t('label.insertURL')"
        >
          <!-- 插入URL label.insertURL -->
          <!-- 请输入要插入的URL  label.please.insertURL -->
          <el-input
            type="textarea"
            v-model="ruleForm.insertURL"
            :placeholder="$t('label.please.insertURL')"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <!-- 取消 -->
    <span slot="footer" class="dialog-footer">
      <el-button @click="newGenerationRuleCancel('ruleForm')">{{
        $t("button_partner_cancel")
      }}</el-button>
      <!-- 为"编辑页面"时显示"保存"按钮 -->
      <el-button
        type="primary"
        @click="newGenerationRuleConfirm('ruleForm')"
        v-show="titleI == 2"
        >{{ $t("label.save") }}</el-button
      >
      <!-- 下一步 -->
      <el-button type="primary" @click="nextStep('ruleForm')">{{
        $t("label.nextstep")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    newGenerationRuleTo: {
      type: Boolean,
      default: false,
    },
    // 1为新增,2为编辑
    titleI: {
      type: String,
      default: "",
    },
    // 新增或编辑标题
    addOrEditTitle: {
      type: String,
      default: "",
    },
    // 需要回显的表单数据
    addNewCodeForm: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      newGenerationRuleToProp: this.newGenerationRuleTo,
      ruleForm: {
        ruleName: "", //规则名称
        associations: this.$i18n.t("label.asset"), //关联对象 label.asset
        codeType: "qrcode", // 码类型
        codeStandards: "QRcodeStandard", // 码标准
        codeSet: "", //代码集
        insert: this.$i18n.t("label.field.insert"), //插入 label.field.insert
        insertWord: "serialnumber", //插入字段
        insertURL: "", //插入URL
      },
      // 码类型
      codeTypeArr: [
        // this.$i18n.t("label.qrcode")
        {
          label: this.$i18n.t("label.qrcode"),
          value: "qrcode",
        },
        // this.$i18n.t("label.barcode")
        {
          label: this.$i18n.t("label.barcode"),
          value: "barcode",
        },
      ],
      //码标准
      codeStandardsArr: [
        {
          label: "QRcode",
          value: "QRcodeStandard",
        },
        {
          label: "Code128",
          value: "Code128Standard",
        },
      ],
      // 代码集
      // codeSetArr: [
      //   {
      //     label: "CODE128A",
      //     value: "CODE128ACodeSet",
      //   },
      //   {
      //     label: "CODE128B",
      //     value: "CODE128BCodeSet",
      //   },
      //   {
      //     label: "CODE128C",
      //     value: "CODE128CCodeSet",
      //   },
      //   {
      //     label: "自动",
      //     value: "automatic",
      //   },
      //   {
      //     label: "默认自动",
      //     value: "defaultAutomatic",
      //   },
      // ],
      //插入字段
      insertWordArr: [
        // this.$i18n.t("label.list.serial.number")
        {
          label: this.$i18n.t("label.list.serial.number"),
          value: "serialnumber",
        },
        // this.$i18n.t("label.unique.identification.number")
        {
          label: this.$i18n.t("label.unique.identification.number"),
          value: "uuid",
        },
      ],
      rules: {
        // 规则名称
        ruleName: [
          {
            required: true,
            message: this.$i18n.t("label.please.rule.name"),
            // 请输入规则名称 this.$i18n.t("label.please.rule.name")
            trigger: "blur",
          },
          {
            min: 1,
            max: 50,
            message: this.$i18n.t("label.ruleName.supportMaximum.50Characters"),
            // 规则名称最多支持50个字符 this.$i18n.t("label.ruleName.supportMaximum.50Characters")
            trigger: "blur",
          },
        ],
        // 关联对象 this.$i18n.t("label.please.associations")
        associations: [
          {
            required: true,
            message: this.$i18n.t("label.please.associations"),
            trigger: "change",
          },
        ],
        // 码类型 this.$i18n.t("label.please.codeType")
        codeType: [
          {
            required: true,
            message: this.$i18n.t("label.please.codeType"),
            trigger: "change",
          },
        ],
        // 码标准 this.$i18n.t("label.please.codeNorm")
        codeStandards: [
          {
            required: true,
            message: this.$i18n.t("label.please.codeNorm"),
            trigger: "change",
          },
        ],
        // 插入URL this.$i18n.t("label.please.insertURL")
        insertURL: [
          {
            required: true,
            message: this.$i18n.t("label.please.insertURL"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    // 监听父组件传来的表单数据，进行回显
    addNewCodeForm: {
      handler() {
        this.ruleForm = JSON.parse(JSON.stringify(this.addNewCodeForm));
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // 当校验插入URL后，又切换radio时，去掉插入URL的校验
    changeRadio(val, formName) {
      if (val == "1") {
        let _field = this.$refs[formName].fields;
        _field.map((item) => {
          if (item.prop == "insertWord") {
            item.resetField();
            return false;
          }
        });
      }
    },
    handleClose() {
      this.$emit("newGenerationRuleCancel");
    },
    // 取消
    newGenerationRuleCancel(formName) {
      this.$refs[formName].resetFields();
      this.$emit("newGenerationRuleCancel");
    },
    // 保存
    newGenerationRuleConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("newGenerationRuleConfirm", this.ruleForm);
        } else {
          return false;
        }
      });
    },
    // 下一步
    nextStep(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("nextStep", this.ruleForm);
        } else {
          return false;
        }
      });
    },
    // 根据"码类型"判断"码标准"和"代码集"的显示
    changeCodeType(value) {
      if (value === "qrcode") {
        this.ruleForm.codeStandards = "QRcodeStandard";
        this.ruleForm.codeSet = "";
      } else if (value === "barcode") {
        this.ruleForm.codeStandards = "Code128Standard";
        // this.ruleForm.codeSet = "automatic";
        this.ruleForm.codeSet = this.$i18n.t("label.dashboardSetup.showdw.d6"); //自动 this.$i18n.t("label.dashboardSetup.showdw.d6")
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 580px;
  .el-dialog__body {
    padding: 26px 36px;
  }
}
.dialog-body {
  // form表单样式
  ::v-deep .demo-ruleForm {
    // 代码集/单选框样式
    .codeSetClass,
    .radioClass {
      margin-bottom: 8px !important;
    }
    // 单选框
    .radioClass {
      .el-radio-group {
        margin-left: -86px;
        .el-radio:first-of-type {
          margin-right: 60px;
        }
      }
    }
    .el-form-item {
      .helpImg {
        display: inline-block;
        margin-left: 10px;
      }
      margin-bottom: 20px;
      // label
      .el-form-item__label {
        min-width: 130px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
        text-align: right;
        font-weight: bold;
        color: #080707;
        // margin-left: 20px;
      }
      // content
      .el-form-item__content {
        margin-left: 150px !important;
        .el-textarea {
          width: 319px;
        }
        .el-input {
          width: 319px;
          height: 30px;
          .el-input__inner {
            height: 30px;
            line-height: 30px;
          }
          .el-input__suffix {
            .el-input__icon {
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}
</style>
